<template>
  <v-container>
    <v-card outlined>
      <v-card-title>
        <h3 class="text-h3">
          Listagem de Acordos - {{ clienteNome }}
        </h3>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
          >
            <v-data-table
              :headers="cobrancasHeaders"
              :items="cobrancasDevedores"
              :expanded.sync="expanded"
              item-key="cobrancaDevedorId"
              show-expand
            >
              <!-- eslint-disable-next-line -->
              <template v-slot:item.cobrancaDevedorId="{ item }">
                #{{ item.cobrancaDevedorId }}
              </template>
              <!-- eslint-disable-next-line -->
              <template v-slot:item.createdAt="{ item }">
                {{ item.createdAt | date }}
              </template>
              <!-- eslint-disable-next-line -->
              <template v-slot:item.valorPedidos="{ item }">
                {{ formater.format(item.valorPedidos) }}
              </template>
              <!-- eslint-disable-next-line -->
              <template v-slot:item.valorCobrado="{ item }">
                {{ formater.format(item.valorCobrado) }}
              </template>
              <!-- eslint-disable-next-line -->
              <template v-slot:item.actions="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="onShowModalBoleto({ ...item })"
                >
                  mdi-eye
                </v-icon>
                <v-icon
                  v-if="(user.roles || []).includes('deletar-acordo')"
                  small
                  @click="onDeleteItem({ ...item })"
                >
                  mdi-delete
                </v-icon>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td
                  :colspan="headers.length"
                  class="pa-0"
                >
                  <v-simple-table dark>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th>Parcela</th>
                          <th>Método de Pagamento</th>
                          <th>Vencimento Parcela</th>
                          <th>Status Pagamento</th>
                          <th>Total Parcela</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="parcela in item.cobrancasDevedoresParcelas"
                          :key="parcela.cobrancaDevedorParcelaId"
                        >
                          <td>{{ parcela.numeroParcela }}</td>
                          <td>{{ parcela.paymentMethod }}</td>
                          <td>{{ parcela.vencimentoParcela | date }}</td>
                          <td>{{ parcela.statusPagamentoTexto }}</td>
                          <td>{{ formater.format(parcela.valorParcela) }}</td>
                          <td>
                            <v-icon
                              v-if="(user.roles || []).includes('pagar-parcela-acordo') && parcela.statusPagamento != statusPagamentoPago"
                              class="mr-2"
                              @click="onShowModalPagamento({ ...parcela })"
                            >
                              mdi-cash-plus
                            </v-icon>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </td>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="error"
          outlined
          @click="onGoInadimplentes"
        >
          <v-icon
            small
            left
          >
            mdi-arrow-left
          </v-icon>
          Voltar
        </v-btn>
        <v-spacer />
        <v-btn
          color="success"
          outlined
          @click="onGoNovoAcordo"
        >
          Novo Acordo
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog
      v-model="showModalBoleto"
      persistent
      max-width="640"
    >
      <v-card>
        <v-card-title>
          <h2 class="text-h5">
            Dados dos Boletos
          </h2>
        </v-card-title>
        <v-card-text>
          <div
            v-for="parcela in cobrancaDevedor.cobrancasDevedoresParcelas"
            :key="parcela.cobrancaDevedorParcelaId"
          >
            <h6 class="text-h6">
              Parcela: {{ parcela.numeroParcela }}
            </h6>
            <p class="pa-0 ma-1">
              Valor: {{ formater.format(parcela.valorParcela) }}
            </p>
            <p class="pa-0 ma-1">
              Vencimento: {{ parcela.vencimentoParcela | date }}
            </p>
            <p class="pa-0 ma-1">
              Código de Barras: {{ parcela.boletoBarcode }}
            </p>
            <p class="pa-0 ma-1">
              Link do Boleto: {{ parcela.boletoUrl }}
            </p>
            <hr class="my-2">
          </div>
          <v-row>
            <v-spacer />
            <v-btn
              color="error"
              text
              @click.stop="onHideModalBoleto"
            >
              Fechar
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showModalDelete"
      max-width="640px"
    >
      <v-card>
        <v-card-title
          class="text-h5"
        >
          Tem certeza de que deseja excluir este item?
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="error"
            @click="onHideModalDelete"
          >
            Não
          </v-btn>
          <v-btn
            color="success"
            outlined
            @click="onDeleteItemConfirm"
          >
            Sim
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showModalPagamento"
      persistent
      max-width="640"
    >
      <v-card>
        <v-card-title
          class="text-h5"
        >
          Pagamento de Parcela
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="parcelaInputModel.transactionId"
                label="Referência de Pagamento"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="parcelaInputModel.paidAt"
                type="date"
                label="Data do Pagamento"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="parcelaInputModel.obs"
                label="Observações"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-spacer />
            <v-btn
              color="error"
              @click="onHideModalPagamento"
            >
              Fechar
            </v-btn>
            <v-btn
              color="success"
              outlined
              @click="onPagamentoParcela"
            >
              Confirmar
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'CobrancasDevedorView',
    data: () => ({
      clienteId: 0,
      clienteNome: 'Nome Cliente',
      statusPagamentoPago: 5,
      cobrancasDevedores: [],
      expanded: [],
      cobrancasHeaders: [
        { text: 'Acordo', value: 'cobrancaDevedorId' },
        { text: 'Data de Criação', value: 'createdAt' },
        { text: 'Total Pedidos', value: 'valorPedidos' },
        { text: 'Total Cobrado', value: 'valorCobrado' },
        { text: '', value: 'actions', sortable: false },
        { text: '', value: 'data-table-expand' },
      ],
      formater: new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }),
      cobrancaDevedor: {
        juros: 0,
        parcelas: 1,
        dataVencimento: '',
        obs: '',
      },
      parcelaSelected: {},
      parcelaInputModel: {
        parcelaId: 0,
        transactionId: '',
        paidAt: '',
        obs: '',
      },
      showModalBoleto: false,
      showModalDelete: false,
      showModalPagamento: false,
    }),
    computed: {
      ...mapGetters(['user']),
    },
    mounted () {
      if (this.$route.params.id) {
        this.debitosEmAberto = []
        this.idSelected = 0
        this.clienteId = this.$route.params.id
        this.loadDataHandler()
      } else {
        this.onGoInadimplentes()
      }
    },
    methods: {
      async loadDataHandler () {
        try {
          const { data } = await this.$http.get(`dividas/cliente/${this.clienteId}`)

          this.clienteNome = data.nome
          this.cobrancasDevedores = data.cobrancasDevedores
        } catch (e) {
          // console.error(e)
          this.$toast.error('Um erro aconteceu!')
        }
      },
      onGoInadimplentes () {
        this.$router.push('/pages/inadimplentes/')
      },
      onGoNovoAcordo () {
        this.$router.push(`/pages/inadimplentes/${this.clienteId}/acordos/novo/`)
      },
      onShowModalBoleto (item) {
        this.cobrancaDevedor = item
        this.showModalBoleto = true
      },
      onHideModalBoleto () {
        this.showModalBoleto = false
        this.cobrancaDevedor = {
          juros: 0,
          parcelas: 1,
          dataVencimento: '',
          obs: '',
        }
      },
      onDeleteItem (item) {
        this.idSelected = item.cobrancaDevedorId
        this.showModalDelete = true
      },
      async onDeleteItemConfirm () {
        try {
          await this.$http.delete(`dividas/renegociacao/${this.idSelected}`)
          this.$toast.success('Item removido com sucesso!')
          this.onHideModalDelete()
        } catch (e) {
          // console.error(e)
          this.$toast.error('Um erro aconteceu!')
        }
      },
      onHideModalDelete () {
        this.showModalDelete = false
        this.loadDataHandler()
        this.$nextTick(() => {
          this.idSelected = 0
        })
      },
      onShowModalPagamento (parcela) {
        this.parcelaSelected = parcela
        this.parcelaInputModel = {}
        this.showModalPagamento = true
      },
      pagamentoValidateHandler () {
        if (!this.parcelaInputModel.transactionId) {
          this.$toast.error('O campo `Referência de Pagamento` é obrigatório!')
          return false
        }
        if (!this.parcelaInputModel.paidAt) {
          this.$toast.error('O campo `Data do Pagamento` é obrigatório!')
          return false
        }

        return true
      },
      async onPagamentoParcela () {
        if (this.pagamentoValidateHandler()) {
          try {
            this.parcelaInputModel.parcelaId = this.parcelaSelected.cobrancaDevedorParcelaId
            await this.$http.put('dividas/parcela', this.parcelaInputModel)
            this.$toast.success('Pagamento realizado com sucesso!')
          } catch (e) {
            // console.error(e)
            this.$toast.error('Um erro aconteceu!')
          }
          this.onHideModalPagamento()
        }
      },
      onHideModalPagamento () {
        this.showModalPagamento = false
        this.loadDataHandler()
        this.$nextTick(() => {
          this.parcelaSelected = {}
          this.parcelaInputModel = {
            parcelaId: 0,
            transactionId: '',
            paidAt: '',
            obs: '',
          }
        })
      },
    },
  }
</script>
